import React from "react";
import PropTypes from "prop-types";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import DesignProps from "../components/molecules/design-props";
import Footer from "../components/organisms/footer";

function DesignNavFooter({ props }) {
  const designProps = props;

  return (
    <div className="design__content design__content--media">
      <SEO title="Design System - Footer" />
      <h2>Footer</h2>
      <div className="design__stories">
        <DesignStory
          title="Footer"
          component={Footer}
          type="nav"
          props={designProps}
        />
        <div className="design__story-section">
          <DesignProps component={Footer} title="Footer Properties" />
        </div>
      </div>
    </div>
  );
}

DesignNavFooter.propTypes = {
  props: PropTypes.objectOf(PropTypes.any),
};

DesignNavFooter.defaultProps = {
  props: {},
};

export default DesignNavFooter;
